<template>
  <div
    class="tracking-wider text-center text-[10px] rounded-full px-1.5 py-0 leading-none flex items-center"
    :class="{'bg-yellow-300 text-slate-900': urgency === 1, 'bg-red-600': urgency === 2}"
  >
    <span class="icon-ico-timer font-bold relative -left-0.5 top-[1px] text-xs"/>
    <span class="whitespace-nowrap font-bold" v-html="expiresInText"/>
  </div>
</template>

<script setup>
const props = defineProps({
  endTime: {
    type: String,
    default: null,
  },
  isClosed: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'ended',
]);

const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const { secondsTicker, } = storeToRefs(uiStore);

const expiresInText = ref();
const urgency = ref(0);
const ended = ref(false);

const endTimeLocal = ref(props.endTime);

setExpiresIn();

function setExpiresIn() {
  const now = $dayjs();
  const endDate = $dayjs(endTimeLocal.value);
  const expiresIn = endDate.diff(now, 's');
  let expiresInTextLocal;

  if (expiresIn < 86400) {
    const totalHours = Math.floor(expiresIn / (60 ** 2));
    const totalMinutes = Math.floor((expiresIn - (totalHours * (60 ** 2))) / 60);
    const totalSeconds = expiresIn - (totalHours * (60 ** 2)) - (totalMinutes * 60);
    // if less than 1 hour, show minutes and seconds only if more than one hour, show hours, minutes only
    if (totalHours < 1) {
      expiresInTextLocal = `${totalMinutes ? totalMinutes + 'm' : ''} ${totalSeconds ? totalSeconds + 's' : ''}`;
    } else {
      expiresInTextLocal = `${totalHours ? totalHours + 'h' : ''} ${totalMinutes ? totalMinutes + 'm' : ''}`;
    }
  } else {
    expiresInTextLocal = `<span class="tabular-nums">${endDate.fromNow(true)}</span>`;
  }

  if (expiresIn > 1800) {
    urgency.value = 1;
  } else if (expiresIn <= 1800) {
    urgency.value = 2;
  } else if (expiresIn <= 0) {
    if (!ended.value) {
      ended.value = true;
      emits('ended');
    }
  }

  if (props.isClosed || expiresIn < 0) {
    expiresInText.value = 'Closed';
  } else {
    expiresInText.value = `<span class="tabular-nums">${expiresInTextLocal}</span>`;
  }
}

watch(
  () => secondsTicker.value,
  () => {
    setExpiresIn();
  }
);
</script>
